<template lang="pug">
  .ID
    form(
      @submit.prevent="handleContinue"
    ).mx-5.mt-10#formUser
      label.relative.max-w-sm.mx-auto.block(@click="scroll200" for="documentType")
        p(v-if="documentType").labelStylesActive.absolute.labelStyles.text-info_color.text-md.truncate.w-full.max-w-full
          | Tipo de documento
        v-select(
          :selected="document_types_filtered[0]"
          :options="document_types_filtered"
          v-model="documentType"
          inputId="documentType"
          @search="verifyCc"
          placeholder="Tipo de documento"
        ).rounded-bd-small.block.relative.transition.duration-500.bg-white.border-none.generalLabelSelect
          template(#no-options="{ search, searching, loading }")
            | No hay tipos de documento
      input-component(
        label="Número de documento"
        v-model="documentNumber"
        id="documentNumber"
        type="number"
        mode="input"
        required
      )
      label.max-w-sm.mx-auto.relative.block(@click="scroll200" for="city")
        p(v-if="cityOrMunicipio").labelStylesActive.absolute.labelStyles.text-info_color.text-md.truncate.w-full.max-w-full
          | Selecciona tu ciudad o municipio
        v-select(
          :options="cities_filtered"
          v-model="cityOrMunicipio"
          @search="verifyCity"
          placeholder="Selecciona tu ciudad o municipio"
          inputId="city"
          :selected="selected"
        ).rounded-bd-small.block.relative.transition.duration-500.bg-white.border-none.generalLabelSelect.mt-3
          template(#no-options="{ search, searching, loading }")
            | No hay ciudades o municipios
      .flex.mt-5.items-center.mx-auto.mx-2.justify-center
        label(for="confirmCafam").maximumLabel.ml-2: small Autorizo de manera voluntaria, explicita, previamente informada e inequívoca a todos los servicios y dependencias de la Caja de Compensación Familiar CAFAM, para tratar mi información personal de conformidad con la Ley 1581 de 2012, el Decreto 1377 de 2013 y la normatividad legal vigente que regula el tratamiento de los datos personales, y de acuerdo con lo establecido en la Política de Tratamiento de Datos Personales de CAFAM, la cual se encuentra disponible en: 
          b: a(href="https://www.cafam.com.co/" target="_blank").text-secondary.underline www.cafam.com.co
      .flex.mt-5.items-center.mx-auto.mx-2.justify-center
        input-component(
          v-model="confirmCafam"
          id="confirmCafam"
          name="confirmCafam"
          mode="checkbox"
        ).mt-0
        label(for="confirmCafam").ml-2: small  Al hacer clic, confirma que autoriza el
          b.ml-1
            a(
              href="https://assets.ctfassets.net/3klebcd0t2ch/7tO5WjD2fQssZxdGiRgJcv/f7a692dbb23e8d59611f93854f05e417/Politica_Proteccion_de_Datos_Personales__f_.pdf"
              target="_blank"
            ).text-secondary.underline
              | tratamiento de datos personales
    footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full.bg-white
      button-component(
        :text="btn_text"
        themeSelected="secondary"
        form="formUser"
        type="submit"
        :disabled="showModal || disabledContinue"
      )
</template>

<script>

import { mapActions, mapState, mapMutations } from "vuex";
// import qs from "qs";
// import $ from 'jquery'
import loadingMessage from "../utils/loadingMessage";
var moment = require("moment");
import _ from 'underscore';

export default {
  name: "ID",
  data() {
    return {
      documentType: "Cédula de ciudadanía",
      oneCreated: 1,
      documentNumber: "",
      cityOrMunicipio: "",
      confirmCafam: "",
      disabledContinue: false,
      btn_text_defatul: 'Continuar',
      btn_text: 'Continuar',
      selected: "",
    }; 
  },
  created() {
    this.reset();
    this.changeUserData({
      documentType: "",
      documentNumber: "",
      cityOrMunicipio: "",
      confirmCafam: "",
    });
    this.setState({ key: "userData", value: {}});

    // if (this.userData) {
    //   this.documentType = this.userData.documentType;
    //   this.documentNumber = this.userData.documentNumber;
    //   this.cityOrMunicipio = this.userData.cityOrMunicipio;
    //   this.confirmCafam = this.userData.confirmCafam;
    // }
  },
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
    InputComponent: () => import("../components/general/Input.vue"),
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.disabledContinue = false;
      }
    },
    documentType() {
      if (this.oneCreated === 1 && !this.userData.documentType) {
        // this.documentType = "Cédula de ciudadanía";
        this.documentType = this.document_types.find(d=>d.id=='CC').name;
        this.oneCreated = 2;
      }
    }
    
  },
  computed: {
    ...mapState({
      document_types: (state) => state.environment.scheduling.document_types,
      cities:  (state) => state.environment.environment.cities,
      points:  (state) => state.environment.environment.points,
      userData: (state) => state.root.userData,
      loading: (state) => state.environment.loading,
      env: (state) => state.environment.environment.env,
      eps: (state) => state.eps.epsState,
      external_token: (state) => state.environment.environment.external_token,
      showModal: (state) => state.root.showModal,
      affiliate: (state) => state.affiliate.affiliate,
      appointments: (state) => state.appointments.appointments,
      closeModal: (state) => state.root.closeModal,
    }),
    document_types_filtered() {

      var document_types = _.sortBy( this.document_types, 'name' );
      return document_types && document_types.length ? document_types.map((dt) => {
        return dt.name;
      }) : [];
    },
    cities_filtered() {
      return _.sortBy(_.uniq(this.points.filter(p=>p.entity_code === this.eps.code).map(p=>p.city)));
      // return this.cities && this.cities.length ? this.cities.map((city) => {
      //   return city.name;
      // }) : [];
    },
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
      "changeUserData",
      "handleShowModal"
    ]),
    ...mapActions([
      "fetchAffiliate", "setTempProcessing", 
    ]),
    ...mapActions(["fetchAppointments"]),
    ...mapActions("formDocuments", [
      "reset"
    ]),
    ...mapMutations("user", [
      "setState",
    ]),
    async handleContinue() {
      try {
        const user = {
          afiliationState: true,
          serviceLine: "",
          name: "",
          email: "",
          lastname: "",
          phone: "",
          address: "",
        };
        this.disabledContinue = true;
        this.clearResponses();

        if (
          !this.documentType ||
          !this.documentNumber ||
          !this.cityOrMunicipio
        ) {
          this.handleShowModal({
            showModal: true,
            modalState: {
              buttonText: "Entendido",
              disabledContinue: false,
              showModal: true,
              responseState: "danger",
              responseToContinueTitle: "Te falta completar datos",
              responseToContinue: `
                Para continuar, valida que haz llenado todos los datos.
              `
            },
          });
          console.error("Error al digitar datos, falta informacion");
          return;
        }

        if (!this.confirmCafam) {
          this.handleShowModal({
            showModal: true,
            modalState: {
              buttonText: "Entendido",
              disabledContinue: false,
              showModal: true,
              responseState: "danger",
              responseToContinueTitle: "Te falta autorizar el tratamiento de datos personales",
              responseToContinue: `
                Para continuar, valida que haz llenado todos los datos.
              `
            },
          });
          console.error("Error al digitar datos, falta autorizar el tratamiento de datos personales");
          return;
        }

        // assign temp processing data 
        this.setTempProcessing({
          document: this.documentNumber,
          timestamp_click: moment().toDate().getTime(),
          appointment_code: null,
          action: 'Aceptación'
        });

        // check user on medicar
        let data = {
          data: {
            'numero_documento': this.documentNumber, 
            'tipo_documento': this.document_types.find(d => d.name.toLowerCase() == this.documentType.toLowerCase()).id,
            // 'ciudad_id:': this.cityOrMunicipio,
            'eps_id': this.eps.code
          },
          url: this.env.VUE_APP_EXTERNAL_SERVER + '/verify-beneficiary/client/1',
          token: this.external_token.access_token,
        }
        
        this.btn_text = 'Validando usuario...';
        this.handleShowModal(loadingMessage({
          title: "Verificando documento...",
          state: "loading",
        }));
        await this.fetchAffiliate(data);
        
        this.disabledContinue = false;
        
        if (this.affiliate.resultado == 0) {
          this.handleShowModal({
            showModal: true,
            modalState: {
              buttonText: "Entendido",
              disabledContinue: false,
              showModal: true,
              responseState: "warning",
              responseToContinueTitle: "Falla en el servicio",
              responseToContinue: this.affiliate.resultado_descripcion
            },
          });
          this.btn_text = this.btn_text_defatul;
          return;
        }
        
        if (!this.affiliate || this.affiliate.resultado == 1 || !this.affiliate.beneficiario) {
          
            this.handleShowModal({
              showModal: true,
              modalState: {
                buttonText: "Entendido",
                disabledContinue: false,
                showModal: true,
                responseState: "warning",
                responseToContinueTitle: 'Beneficiario Inactivo, comunícate con tu EPS',
                responseToContinue: this.affiliate.resultado_descripcion
              },
            });
            this.btn_text = this.btn_text_defatul;
            return;
            
        }

        user.name = this.affiliate.beneficiario;

        // if (this.affiliate.resultado == 2) {
          
        //   this.handleShowModal({
        //     showModal: true,
        //     modalState: {
        //       buttonText: "Entendido",
        //       disabledContinue: false,
        //       showModal: true,
        //       responseState: "warning",
        //       responseToContinueTitle: 'Beneficiario válido',
        //       responseToContinue: this.affiliate.resultado_descripcion
        //     },
        //   });
          
        // }

        // set user data
        let city = this.cities.find(c=> {
          return c.name.toLowerCase().trim() == this.cityOrMunicipio.toLowerCase().trim();
        } )

        // setting userData
        this.changeUserData({
          dcType: data.data.tipo_documento,
          documentType: this.documentType,
          documentNumber: data.data.numero_documento,
          cityOrMunicipio: this.cityOrMunicipio,
          cityId: city?.id,
          confirmCafam: this.confirmCafam,
          ...user,
        });
        this.closeModal();
        this.btn_text = this.btn_text_defatul;
        this.changeTrip("ValidationData");
      } catch (error) {
        console.error("[error: Falla en el servicio]:", error);
        this.handleShowModal(loadingMessage({
          title: "Lo sentimos ocurrió un error al validar tu documento",
          state: "danger",
        }));
        this.btn_text = 'Continuar';
      }
    },
    clearResponses() {
      this.handleShowModal({
        showModal: false,
        modalState: {},
      });
    },
    scroll200() {
      const $main = document.querySelector("main.Main");
      if ($main) {
        $main.scrollTo(0, 200);
      }
    },
    verifyCc(search) {
      const filtered = this.document_types.filter((dt) => dt.name.includes(search.toUpperCase()));
      if (filtered.length === 1) {
        document.querySelectorAll(".vs__search")?.forEach((vs) => {
          vs.blur();
        });
        this.documentType = filtered[0].name;
      }
    },
    verifyCity(search) {
      const filtered = this.cities.filter((city) => city.name.includes(search.toUpperCase()));
      if (filtered.length === 1) {
        document.querySelectorAll(".vs__search")?.forEach((vs) => {
          vs.blur();
        });
        this.selected = filtered[0].name;
        this.cityOrMunicipio = filtered[0].name;
      }
    },
  },
}
</script>

<style scoped>
.generalLabelSelect {
  border-bottom: 2px solid rgb(219, 217, 217);
  padding: 8px;
  padding-top: 16px;
}

.labelStylesActive {
  color: #2E6AB3;
  transform: translateY(-15%) translateX(-30px) scale(0.8);
  z-index: 100;
}

.maximumLabel {
  max-width: 416px;
}
</style>
