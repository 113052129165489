var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ID"},[_c('form',{staticClass:"mx-5 mt-10",attrs:{"id":"formUser"},on:{"submit":function($event){$event.preventDefault();return _vm.handleContinue.apply(null, arguments)}}},[_c('label',{staticClass:"relative max-w-sm mx-auto block",attrs:{"for":"documentType"},on:{"click":_vm.scroll200}},[(_vm.documentType)?_c('p',{staticClass:"labelStylesActive absolute labelStyles text-info_color text-md truncate w-full max-w-full"},[_vm._v("Tipo de documento")]):_vm._e(),_c('v-select',{staticClass:"rounded-bd-small block relative transition duration-500 bg-white border-none generalLabelSelect",attrs:{"selected":_vm.document_types_filtered[0],"options":_vm.document_types_filtered,"inputId":"documentType","placeholder":"Tipo de documento"},on:{"search":_vm.verifyCc},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v("No hay tipos de documento")]}}]),model:{value:(_vm.documentType),callback:function ($$v) {_vm.documentType=$$v},expression:"documentType"}})],1),_c('input-component',{attrs:{"label":"Número de documento","id":"documentNumber","type":"number","mode":"input","required":""},model:{value:(_vm.documentNumber),callback:function ($$v) {_vm.documentNumber=$$v},expression:"documentNumber"}}),_c('label',{staticClass:"max-w-sm mx-auto relative block",attrs:{"for":"city"},on:{"click":_vm.scroll200}},[(_vm.cityOrMunicipio)?_c('p',{staticClass:"labelStylesActive absolute labelStyles text-info_color text-md truncate w-full max-w-full"},[_vm._v("Selecciona tu ciudad o municipio")]):_vm._e(),_c('v-select',{staticClass:"rounded-bd-small block relative transition duration-500 bg-white border-none generalLabelSelect mt-3",attrs:{"options":_vm.cities_filtered,"placeholder":"Selecciona tu ciudad o municipio","inputId":"city","selected":_vm.selected},on:{"search":_vm.verifyCity},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v("No hay ciudades o municipios")]}}]),model:{value:(_vm.cityOrMunicipio),callback:function ($$v) {_vm.cityOrMunicipio=$$v},expression:"cityOrMunicipio"}})],1),_vm._m(0),_c('div',{staticClass:"flex mt-5 items-center mx-auto mx-2 justify-center"},[_c('input-component',{staticClass:"mt-0",attrs:{"id":"confirmCafam","name":"confirmCafam","mode":"checkbox"},model:{value:(_vm.confirmCafam),callback:function ($$v) {_vm.confirmCafam=$$v},expression:"confirmCafam"}}),_vm._m(1)],1)],1),_c('footer',{staticClass:"Footer fixed p-5 pt-2 max-w-lg w-full bg-white"},[_c('button-component',{attrs:{"text":_vm.btn_text,"themeSelected":"secondary","form":"formUser","type":"submit","disabled":_vm.showModal || _vm.disabledContinue}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex mt-5 items-center mx-auto mx-2 justify-center"},[_c('label',{staticClass:"maximumLabel ml-2",attrs:{"for":"confirmCafam"}},[_c('small',[_vm._v("Autorizo de manera voluntaria, explicita, previamente informada e inequívoca a todos los servicios y dependencias de la Caja de Compensación Familiar CAFAM, para tratar mi información personal de conformidad con la Ley 1581 de 2012, el Decreto 1377 de 2013 y la normatividad legal vigente que regula el tratamiento de los datos personales, y de acuerdo con lo establecido en la Política de Tratamiento de Datos Personales de CAFAM, la cual se encuentra disponible en: "),_c('b',[_c('a',{staticClass:"text-secondary underline",attrs:{"href":"https://www.cafam.com.co/","target":"_blank"}},[_vm._v("www.cafam.com.co")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"ml-2",attrs:{"for":"confirmCafam"}},[_c('small',[_vm._v(" Al hacer clic, confirma que autoriza el"),_c('b',{staticClass:"ml-1"},[_c('a',{staticClass:"text-secondary underline",attrs:{"href":"https://assets.ctfassets.net/3klebcd0t2ch/7tO5WjD2fQssZxdGiRgJcv/f7a692dbb23e8d59611f93854f05e417/Politica_Proteccion_de_Datos_Personales__f_.pdf","target":"_blank"}},[_vm._v("tratamiento de datos personales")])])])])}]

export { render, staticRenderFns }